<template>
    <div class="home">
      <swiper :options="swiperOptions">
        <swiper-slide v-for="(item, index) in items" :key="index">
          <img :src="item.image" alt="Imagem do Slide" class="responsive-image">
        </swiper-slide>
      </swiper>
    </div>

</template>

<script>
import { Swiper, SwiperSlide} from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: 
      [
        { image: require('@/assets/home-banner/1.png'), text: 'Texto do Slide 1' }
      ],
      swiperOptions: {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 500,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 2000, // Tempo em milissegundos (2 segundos)
          disableOnInteraction: false,
        },
      },
    };
  },
}
</script>
<style scoped>
.home {
  max-width: 100%;
  width: 100%;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}
</style>