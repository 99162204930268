<template>
  <div class="container">
    <div class="row-menu-item">
                    <h2>Confira nossos planos de Internet via Fibra Óptica</h2>
    </div>
    <div class="row row-menu-item col-md-12 col-sm-12 oferta-main">
      <div class="oferta-box shadow-dreamy">
        <div>
          <h3 class="titulo-oferta">50 MEGA</h3>
        </div>
        <div>
          <span>Internet Fibra Óptica</span>
        </div>
        <div>
          <ul class="oferta">
										<li>Wi-fi de alta performance
										</li>
										<li>Download de
											<span>50mbps</span></li>
										<li>Upload de
											<span>25mbps</span></li>
										<li>
										<img ref="imagem1080p" loading="lazy" src="../assets/planos-content/full-hd.png" >
										<img ref="celular50mb" loading="lazy" src="../assets/planos-content/ico_cel.png" >
										<img ref="jogo50mb" loading="lazy" src="../assets/planos-content/ico_joystick.png">
										<img ref="notebook50mb" loading="lazy" src="../assets/planos-content/ico_notebook.png">
										</li>
										<li>
											<span>Total/mês:</span>
										</li>
										<li>
											<h5>R$ 69,90</h5>
										</li>
										<li>
											<span>*até o vencimento</span>
										</li>
									
                    <li class="btn-plano">
                      <a href="https://api.whatsapp.com/send?phone=5571992574841&text=Ol%C3%A1!%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20plano%20de%2050MEGA por 69,90%20." target="_blank" class="nav-link">QUERO ESSE</a>
                   </li>
            
          </ul>
        </div>
      </div>
      <div class="oferta-box shadow-dreamy">
        <div>
          <h3 class="titulo-oferta">150 MEGA</h3>
        </div>
        <div>
          <span>Internet Fibra Óptica</span>
        </div>
        <div>
          <ul class="oferta">
										<li>Wi-fi de alta performance
										</li>
										<li>Download de
											<span>150mbps</span></li>
										<li>Upload de
											<span>70mbps</span></li>
										<li>
                    <img ref="imagem100mega" loading="lazy" src="../assets/planos-content/4k.png" >
										<img ref="celular100mega" loading="lazy" src="../assets/planos-content/ico_cel.png" >
										<img ref="jogo100mega" loading="lazy" src="../assets/planos-content/ico_joystick.png">
										<img ref="notebook100mega" loading="lazy" src="../assets/planos-content/ico_notebook.png">
										</li>
										<li>
											<span>Total/mês:</span>
										</li>
										<li>
											<h5>R$ 89,90</h5>
										</li>
										<li>
											<span>*até o vencimento</span>
										</li>
                    <li class="btn-plano">
                      <a href="https://api.whatsapp.com/send?phone=5571992574841&text=Ol%C3%A1!%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20plano%20de%20100MEGA por 89,90%20." target="_blank" class="nav-link">QUERO ESSE</a>
                   </li>
          </ul>
        </div>
      </div>
      <div class="oferta-box shadow-dreamy">
        <div>
          <h3 class="titulo-oferta">250 MEGA</h3>
        </div>
        <div>
          <span>Internet Fibra Óptica</span>
        </div>
        <div>
          <ul class="oferta">
										<li>Wi-fi de alta performance
										</li>
										<li>Download de
											<span>250mbps</span></li>
										<li>Upload de
											<span>125mbps</span></li>
										<li>
                    <img ref="imagem250mega" loading="lazy" src="../assets/planos-content/4k.png" >
										<img ref="celular250mega" loading="lazy" src="../assets/planos-content/ico_cel.png" >
										<img ref="jogo250mega" loading="lazy" src="../assets/planos-content/ico_joystick.png">
										<img ref="notebook250mega" loading="lazy" src="../assets/planos-content/ico_notebook.png">
										</li>
										<li>
											<span>Total/mês:</span>
										</li>
										<li>
											<h5>R$ 129,90</h5>
										</li>
										<li>
											<span>*até o vencimento</span>
										</li>
                    <li class="btn-plano">
                      <a href="https://api.whatsapp.com/send?phone=5571992574841&text=Ol%C3%A1!%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20plano%20de%20250MEGA por 129,90%20." target="_blank" class="nav-link">QUERO ESSE</a>
                   </li>
          </ul>
        </div>
      </div>
      <div class="oferta-box shadow-dreamy">
        <div>
          <h3 class="titulo-oferta">350 MEGA</h3>
        </div>
        <div>
          <span>Internet Fibra Óptica</span>
        </div>
        <div>
          <ul class="oferta">
										<li>Wi-fi de alta performance
										</li>
										<li>Download de
											<span>350mbps</span></li>
										<li>Upload de
											<span>175mbps</span></li>
										<li>
                    <img ref="imagem350mega" loading="lazy" src="../assets/planos-content/8k.png" >
										<img ref="celular350mega" loading="lazy" src="../assets/planos-content/ico_cel.png" >
										<img ref="jogo350mega" loading="lazy" src="../assets/planos-content/ico_joystick.png">
										<img ref="notebook350mega" loading="lazy" src="../assets/planos-content/ico_notebook.png">
										</li>
										<li>
											<span>Total/mês:</span>
										</li>
										<li>
											<h5>R$ 159,90</h5>
										</li>
										<li>
											<span>*até o vencimento</span>
										</li>
                    <li class="btn-plano">
                      <a href="https://api.whatsapp.com/send?phone=5571992574841&text=Ol%C3%A1!%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20plano%20de%20350MEGA por 159,90%20." target="_blank" class="nav-link">QUERO ESSE</a>
                   </li>
          </ul>
        </div>
      </div>
											<span style="padding-top: 2%;font-style: italic;">*Consulte disponibilidade para sua região!</span>						
    </div>
  </div>
  
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';

export default {
  mounted() {
    const tooltips1080p = [
      { target: this.$refs.imagem1080p, content: 'Ideal para assistir vídeo em até 1080p', theme: 'light' },
      { target: this.$refs.celular50mb, content: 'Ideal para até 4 celulares simultâneos', theme: 'light' },
      { target: this.$refs.jogo50mb, content: 'Ideal para jogos online que não exigem muito', theme: 'light' },
      { target: this.$refs.notebook50mb, content: 'Ideal para envio de emails, documentos, pesquisas e navegação', theme: 'light' },
    ];

    const tooltips100mega = [
      { target: this.$refs.imagem100mega, content: 'Ideal para assistir vídeo em até 4K', theme: 'light' },
      { target: this.$refs.celular100mega, content: 'Ideal para até 6 celulares simultâneos', theme: 'light' },
      { target: this.$refs.jogo100mega, content: 'Ideal para jogos que contêm pouca atualização', theme: 'light' },
      { target: this.$refs.notebook100mega, content: 'Ideal para navegação, downloads e streaming de vídeos', theme: 'light' },
    ];
    const tooltips250mega = [
      { target: this.$refs.imagem250mega, content: 'Ideal para assistir vídeo em até 4K', theme: 'light' },
      { target: this.$refs.celular250mega, content: 'Ideal para até 6 celulares simultâneos', theme: 'light' },
      { target: this.$refs.jogo250mega, content: 'Ideal para jogos que contêm atualização periódica', theme: 'light' },
      { target: this.$refs.notebook250mega, content: 'Ideal para downloads,streaming de vídeos ou uploads de arquivos', theme: 'light' },
    ];
    const tooltips350mega = [
      { target: this.$refs.imagem350mega, content: 'Ideal para assistir vídeo em até 8K', theme: 'light' },
      { target: this.$refs.celular350mega, content: 'Ideal para até 6 celulares simultâneos', theme: 'light' },
      { target: this.$refs.jogo350mega, content: 'Ideal para jogos que contêm atualização constante', theme: 'light' },
      { target: this.$refs.notebook350mega, content: 'Ideal para downloads,streaming de vídeos ou uploads de arquivos pesados', theme: 'light' },
    ];
    // Inicialize os tooltips para ficar bonito
    this.initializeTooltips(tooltips1080p);
    this.initializeTooltips(tooltips100mega);
    this.initializeTooltips(tooltips250mega);
    this.initializeTooltips(tooltips350mega);
  },

  methods: {
    initializeTooltips(tooltips) {
      tooltips.forEach((tooltip) => {
        tippy(tooltip.target, {
          content: tooltip.content,
          theme: tooltip.theme,
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: auto;
    margin-left: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 60px;
}
.btn-plano{
  background-color: #ffd700; /* Cor mostarda */
  color: #000 !important; /* Cor do texto preto */
  border-radius: 5px; /* Borda arredondada */
  transition: background-color 0.3s ease, color 0.3s ease; /* Efeito de transição suave */
  font-weight: bold !important;
  font-size: 18px !important;
    width: 170px;
    height: 45px;
  /* Adiciona sombra quando o mouse passa sobre o botão */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Remove o contorno do botão */
  border: none;
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
  padding-inline: 20px;
  padding-right: 20px;
}
  /* Estilizações quando o mouse passa sobre o botão */
  .btn-plano:hover {
    background-color: #fff; /* Cor branca ao passar o mouse */
    color: #000; /* Mantém a cor do texto preto ao passar o mouse */
  }
.row-menu-item {
    margin: auto;
    text-align: center;
    margin-bottom: 3rem;
}
.titulo-oferta {
    font-weight: bold;
    font-size: 32px;
    color: black;
    margin: 0;
}
.oferta-box>:nth-child(3)>ul>:nth-child(5)>span {
    font-weight: 400;
    font-size: 14px;
    color: #666;
}
.oferta-box>:first-child {
    margin-top: 25px;
}
.oferta-box>:nth-child(2) {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 15px;
    width: 80%;
    margin: auto;
}
.oferta-box>:nth-child(3) {
    width: 80%;
    margin: auto;
}
.oferta-box>:nth-child(3)>ul>li {
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 300;
    color: #cd1515;
}
ul.oferta>:nth-child(1) {
    color: #cd1515;
}
.oferta-box>:nth-child(3)>ul>:nth-child(4) {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
}
.oferta-box>:nth-child(3)>ul>:nth-child(5) {
    padding-bottom: 0;
    padding-top: 12px;
}
.oferta-box>:nth-child(3)>ul>:nth-child(6) {
    padding-bottom: 0;
}
.oferta-box>:nth-child(3)>ul>:nth-child(6)>h5 {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0;
    color: #cd1515;
}
.oferta-box>:nth-child(3)>ul>li>span {
    font-weight: bold;
}
h5 {
    color: #cd1515;
}
.h5, h5 {
    font-size: 1.25rem;
}
.oferta-box>:nth-child(3)>ul>li {
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 300;
    color: #cd1515;
}
.oferta-box>:nth-child(3)>ul>li>img {
    padding-right: 7px;
    color: #cd1515;
}
.oferta-box>:nth-child(3)>ul>:nth-child(4) {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
}
.oferta-box>:nth-child(3)>ul>li {
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 300;
    color: #cd1515;
}

ul.oferta>:last-child {
    text-align: center;
    padding-top: 1rem;
}
.oferta-box>:nth-child(3)>ul>:nth-child(7)>span {
    font-weight: lighter;
    font-size: 11px;
    color: #666;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
*, *::before, *::after {
    box-sizing: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
.row-menu-item {
    margin: auto;
    text-align: center;
    margin-bottom: 3rem;
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.oferta-main {
    padding: 0;
    justify-content: center;
}
.oferta-box {
    margin-right: 20px;
    width: 260px;
    height: 441px;
}
.shadow-dreamy {
    -webkit-box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.85);
    box-shadow: 0px 2px 13px 4px rgb(0 0 0 / 8%);
    
}
.oferta {
    text-align: left;
    padding-top: 7px;
    
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

</style>
