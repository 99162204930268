<template>
    <div class="container">
 <div class="row justify-content-between align-items-center">
      <div class="col-md-6">
          <img alt="Image" src="@/assets/home-content/bg-apps.png" class="responsive-image">
        </div>

        <div class="col-md-6">
            <h2 class="title">Sem preocupações!</h2>
            <p>Navegue, assista filmes em alta qualidade, jogue online, faça downloads sem queda. Aqui você tem mais velocidade, cobertura e estabilidade da rede para a sua casa e também a sua empresa.</p>
            <hr class="short">
          
        </div>

      </div>

  <h1 class="title text-center">Mude para a BBC Internet</h1>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-4">
      <div class="card">
        <figure class="icon-bg">
          <img src="../assets/home-content/review.svg" alt="Melhor Banda Larga">
        </figure>
        <h4>Melhor Conexão</h4>
        <p>Com a BBC você navega de forma ilimitada usando a sua internet sem precisar se preocupar com a franquia ou qualquer outra limitação. A entrega de velocidade é garantida e sem combos.</p>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <div class="card">
        <figure class="icon-bg">
          <img src="../assets/home-content/apoio-suporte.png" alt="Navegue Sem Medo">
        </figure>
        <h4>Suporte Qualificado</h4>
        <p>Tenha a sua disposição um suporte eficiente,ágil e qualificado.Contamos com uma equipe altamente dedicada, pronto para resolver todas as suas dúvidas.</p>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <div class="card">
        <figure class="icon-bg">
          <img src="@/assets/home-content/stopwatch.svg" alt="Turbine Sua Internet" >
        </figure>
        <h4>Turbine Sua Internet</h4>
        <p>Cansado de sua atual internet? Temos planos de até <b>350 MEGA</b> pra você e sua família curtir sem preocupação. Planos para todos os perfis, sem combos e sem surpresas.</p>
      </div>
    </div>
  </div>
      <div class="row justify-content-between align-items-center">
        <div class="col-md-6">
          <div class="col-md-12">
            <h2 class="title">Você sempre conectado!</h2>
            <p>Assista a filmes e séries, canais ao vivo, desenhos, esportes ou maratone a vontade na TV, computador, 
              celular ou tablet. Com a BBC Internet você curte os melhores conteúdos em alta definição sem se preocupar com travamentos e interrupções.</p>
            <hr class="short">
          </div>
        </div>
        <div class="col-md-6 row justify-content-between align-items-center">
          <img alt="Image" src="@/assets/home-content/homepage-banner-3.png" class="responsive-image">
        </div>
      </div>


  </div>
</template>
<style scoped>
.responsive-image {
  max-width: 100%;
  height: auto;
}
  .card {
    padding: 20px;
    margin-bottom: 20px; 
  }

.row-menu-item-secondary {
    margin: auto;
    text-align: center;
    margin-top: 3rem;
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    align-items: center;
    margin-top: 2rem;
}
.title {
    font-size: 30px;
    line-height: 58px;
    font-family: "Gotham",sans-serif;
    color: #cd1515;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 1%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
