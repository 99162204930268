<template>
  <div class="not-found">
    <div class="content">
      <h1>404</h1>
      <p>Desculpe, a página que você está procurando não foi encontrada.</p>
      <router-link to="/">Voltar para a página inicial</router-link>
    </div>
  </div>
</template>

<style scoped>
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.content {
  text-align: center;
}

h1 {
  font-size: 8em;
  margin: 0;
  color: #42b983;
}

p {
  font-size: 1.5em;
}

router-link {
  margin-top: 20px;
  color: #42b983;
  text-decoration: underline;
}
</style>